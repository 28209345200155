import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { MDXProvider } from '@mdx-js/react'
import { graphql } from 'gatsby'
import type { Article } from '../types'
import { googleStructuredData } from '../utils'
import { BaseLayout, En, Example, ExternalLink, Image, NextAndPrevious, PostMeta } from '../components'
import { siteMetaData } from '../variables'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const shortcodes = { En, Example, ExternalLink, Image }

interface Props {
  readonly data: {
    readonly mdx: {
      readonly body: string
    }
  }
  readonly pageContext: {
    readonly frontmatter: Article['frontmatter']
    readonly next: Article['next']
    readonly previous: Article['previous']
  }
}

export default function Page(props: Props) {
  const {
    pageContext: { frontmatter, next, previous },
    data: {
      mdx: { body },
    },
  } = props
  const keywords = [...frontmatter.tags, ...(frontmatter.keywords != null ? frontmatter.keywords : [])]
  const datePublished = new Date(frontmatter.publishedDate)
  const dateModified = frontmatter.updatedDate ? new Date(frontmatter.updatedDate) : undefined
  const Meta = () => <PostMeta tags={frontmatter.tags} datePublished={datePublished} dateModified={dateModified} />
  const imageURL = new URL(siteMetaData.baseURL)
  imageURL.pathname = frontmatter.thumbnail

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'ja' }}>
        <title>{frontmatter.title}</title>
        <meta name="description" content={frontmatter.bodyPrefix} />
        <meta name="keywords" content={keywords.join(', ')} />
        <meta name="og:title" content={frontmatter.title} />
        <meta name="og:description" content={frontmatter.bodyPrefix} />
        <meta name="og:site_name" content={siteMetaData.siteName} />
        <meta name="og:image" content={imageURL.toString()} />
        <meta name="twitter:card" content="summary" />
        <script type="application/ld+json">
          {googleStructuredData({
            headline: frontmatter.title,
            description: frontmatter?.description || frontmatter.bodyPrefix, // NOTE: Do not use `??` to avoid shwoing an empty description
            datePublished,
            dateModified,
            image: imageURL,
          })}
        </script>
      </Helmet>
      <BaseLayout>
        <Container>
          <MDXProvider components={{ ...shortcodes, Meta }}>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
          <NextAndPrevious next={next} previous={previous} />
        </Container>
      </BaseLayout>
    </>
  )
}

const Container = styled.article`
  margin: 0 auto;
  max-width: 100%;
`

export const pageQuery = graphql`
  query MDXQuery($id: String!) {
    mdx(id: { eq: $id }) {
      body
    }
  }
`
